/* eslint-disable */
import React from "react";

export default function Footer() {
  return (
    <>
      <div className="container-fluid">
          <div className="row footer">
              <p>© 2021 - {new Date().getFullYear()} CRYPTOCUNTS CLUB</p>
          </div>
      </div>
    </>
  );
}
